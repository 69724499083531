<template>
  <div v-if="loaded" class="print-box">
    <div class="print-left" :style="{ height: `${this.$util.getViewHeight() - 162}px` }">
      <div id="print-content" class="print-content">
        <!-- title -->
        <div class="title-box">
          <h1 class="title-main">{{ formData.saler_company_name }}</h1>
          <h2 class="title-sub">
            销售成本核算单
            <span class="title-number">
              <span>NO.</span>
              <span class="title-number-red">{{ formData.bill_code }}</span>
            </span>
          </h2>
        </div>

        <!-- formData1 -->
        <div class="form-box">
          <div class="form-list" v-for="(itemSt, indexSt) in formData1" :key="indexSt">
            <div class="form-item" v-for="(itemNd, indexNd) in itemSt" :key="indexNd">
              <div class="form-key">{{ itemNd.key }}{{ itemNd.key && '：' }}</div>
              <div class="form-val" v-if="itemNd.val">
                <span v-if="itemNd.val === 'bill_date'">
                  {{ formData[itemNd.val].slice(0, 10) || '&nbsp;' }}
                </span>
                <span v-else>
                  {{ formData[itemNd.val] || '&nbsp;' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- tableData -->
        <div class="table-box">
          <PrintTable tableTitle="bom表清单" :parent="null" :data="tableData" />
        </div>
        <!-- formData2 -->
        <div class="form-box">
          <div class="form-list" v-for="(itemSt, indexSt) in formData2" :key="indexSt">
            <div
              class="form-item"
              :style="{ flex: itemNd.flex }"
              v-for="(itemNd, indexNd) in itemSt"
              :key="indexNd"
            >
              <div class="form-key">{{ itemNd.key }}{{ itemNd.key && '：' }}</div>
              <div :class="{ 'form-val': true, border: itemNd.border }">
                {{ itemNd.prev
                }}{{ formData[itemNd.val] || (itemNd.key === '销售总监确认' ? '&nbsp;' : '0')
                }}{{ itemNd.next }}
              </div>
            </div>
          </div>
        </div>

        <!-- prompt -->
        <div v-if="false" class="prompt-box">
          <div class="prompt-left">
            <ul>
              <li>请确保所有输入的数据准确无误，包括产品成本、销售价格、税率等。</li>
              <li>本核算单需经过销售经理审批后方可生效。</li>
              <li>本核算单涉及公司内部敏感数据，请妥善保管，避免外泄。</li>
              <li>如在核算过程中有任何问题，请及时联系财务部或相关负责人。</li>
            </ul>
          </div>
          <div class="prompt-right">二维码</div>
        </div>
      </div>
    </div>
    <div class="print-right">
      <div class="print-do">
        <div class="title">
          单据状态：<span :style="{ color: statusObj.color }">{{ statusObj.name }}</span>
        </div>
        <div v-if="formData.status === 0">
          <el-button class="print-btn" @click="itemPush" type="success" size="small">
            提交审核
          </el-button>
        </div>
        <div v-if="formData.status === 1 && $tool.hasControl(['/gcvip/checkBill/leaderDoAudit'])">
          <el-button class="print-btn" @click="itemPass" type="success" size="small">
            审核通过
          </el-button>
        </div>
        <div v-if="formData.status === 1 && $tool.hasControl(['/gcvip/checkBill/leaderReject'])">
          <el-button class="print-btn" @click="itemReject" type="danger" size="small">
            审核拒绝
          </el-button>
        </div>
        <div>
          <el-button class="print-btn" v-print="printData" type="primary" size="small">
            打印
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintTable from './print-table'
export default {
  components: {
    PrintTable
  },
  data() {
    return {
      loaded: false,
      formData: {},
      printData: {
        id: 'print-content',
        popTitle: '&nbsp;'
        // preview: true, // 是否开启预览
        // previewTitle: '打印预览', // 预览标题
      },
      formData1: [
        [
          {
            key: '客户名称',
            val: 'customer_name'
          },
          {
            key: '客户联系人',
            val: 'linkman'
          },
          {
            key: '客户电话',
            val: 'phone'
          }
        ],
        [
          {
            key: '销售日期',
            val: 'bill_date'
          },
          {
            key: '业务员',
            val: 'saler_name'
          },
          {
            key: '备注',
            val: 'bill_remark'
          }
        ]
      ],
      formData2: [
        [
          {
            key: '销售运费',
            val: 'freight_fees_bill',
            prev: '¥',
            border: true
          },
          {
            key: '打包费',
            val: 'pack_fees_bill',
            prev: '¥',
            border: true
          },
          {
            key: '其他费用',
            val: 'other_fees_bill',
            prev: '¥',
            border: true
          },
          {
            key: '税率',
            val: 'bill_tax_rate',
            next: '%',
            border: true
          },
          {
            key: '税金',
            val: 'tax_fees_total',
            prev: '¥',
            border: true
          },
          {
            key: '费用合计',
            val: 'fees_total',
            prev: '¥',
            border: true
          }
        ],
        [
          {
            key: '订单销售金额',
            val: 'sales_amount_total',
            prev: '¥'
          },
          {
            key: '订单成本',
            val: 'cost_price_total',
            prev: '¥'
          },
          {
            key: '订单利润',
            val: 'profit_total',
            prev: '¥'
          }
        ],
        [
          {
            key: '订单利润率',
            val: 'profit_rate_total',
            next: '%'
          },
          {
            key: '提成金额',
            val: 'push_money',
            prev: '¥'
          },
          {
            key: '销售总监确认',
            val: ''
          }
        ]
      ],
      tableData: []
    }
  },
  computed: {
    billId() {
      return this.$route.query.id
    },
    statusObj() {
      let result = {
        name: '',
        color: ''
      }
      if (this.formData.status === 0) {
        result.name = '暂存'
        result.color = '#999'
      }
      if (this.formData.status === 1) {
        result.name = '审核中'
        result.color = '#66b1ff'
      }
      if (this.formData.status === 2) {
        result.name = '审核通过'
        result.color = '#67c23a'
      }
      return result
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.loaded = false
      this.$api({
        method: 'post',
        url: '/admin/gcvip/checkBill/detail',
        params: {
          id: this.billId
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            // formData
            let formData = res.data.formData
            if (formData.company) {
              formData.customer_name = formData.company.customer_name
              formData.linkman = formData.company.linkman
              formData.phone = formData.company.phone
            }
            this.formData = formData
            this.tableData = res.data.tableSaleData
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    },
    // 项-提交审核
    itemPush() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/salerDoAudit',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已提交')
          this.getDetailData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审核通过
    itemPass() {
      this.$confirm(`销售单号：${this.formData.bill_code}，该核算单是否通过审核?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.itemPassConfirm()
      })
    },
    // 项-审核通过-确认
    itemPassConfirm() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/leaderDoAudit',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已通过')
          this.getDetailData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审核拒绝
    itemReject() {
      this.$confirm(`销售单号：${this.formData.bill_code}，该核算单是否拒绝审核?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.itemRejectConfirm()
      })
    },
    // 项-审核拒绝-确认
    itemRejectConfirm() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/leaderReject',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已拒绝')
          this.getDetailData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
// print
.print-box {
  display: flex;
  background: #f5f7fa;
  .print-left {
    margin: 10px;
    padding: 20px;
    width: 712px;
    background: #fff;
    overflow-y: auto;
    .scroll-bar();
  }
  .print-right {
    margin: 10px 10px 10px 0;
    padding: 10px;
    width: 200px;
    background: #fff;
    .print-btn {
      width: 100%;
    }
    .print-do {
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;
      font-size: 14px;
      background: #f8f8f8;
      .title {
        color: #666;
      }
      .el-button {
        margin-top: 10px;
      }
    }
  }
}

// content
.print-content {
  font-size: 12px;
  line-height: 1.5;
  font-family: '宋体';
  overflow: hidden;
}

// title
.title-box {
  margin: 10px 0 30px 0;
  text-align: center;
  font-family: '黑体';
}
.title-main {
  margin: 0;
  font-size: 20px;
}
.title-sub {
  position: relative;
  margin: 10px 0 0 0;
  font-size: 20px;
}
.title-number {
  position: absolute;
  top: 0;
  right: 0;
}
.title-number-red {
  color: #cf2438;
}

// form
.form-box {
  margin-top: 10px;
}
.form-list {
  display: flex;
  align-items: center;
}
.form-list ~ .form-list {
  margin-top: 5px;
}
.form-item {
  flex: 1;
  display: flex;
  align-items: center;
}
.form-item ~ .form-item .form-key {
  margin-left: 10px;
}
.form-key {
  border-bottom: 1px solid #fff;
}
.form-val {
  flex: 1;
  padding: 2px 0;
  border-bottom: 1px solid #000;
  &.border {
    border-color: #fff;
  }
}

// table
.table-box {
  margin: 20px 0;
}

// prompt
.prompt-box {
  display: flex;
  margin-top: 20px;
  border: 1px solid #000;
}
.prompt-left {
  flex: 1;
  padding: 10px;
  line-height: 2;
}
.prompt-left ul {
  margin: 0;
}
.prompt-right {
  padding: 10px;
  width: 96px;
  line-height: 96px;
  text-align: center;
  border-left: 1px solid #000;
}
</style>
