<template>
  <div>
    <table class="table-box">
      <caption>
        <div v-if="tableTitleArr.length > 1" class="table-title">
          <span v-for="(item, index) in tableTitleArr" :key="index">
            <span :class="item.isActive ? 'c-green' : ''">{{ item.name }}</span>
            <span v-show="!item.isActive && item.name">-</span>
          </span>
        </div>
        <div v-else class="table-title">
          <span class="c-green">销售清单</span>
        </div>
      </caption>
      <thead>
        <tr>
          <th width="24">序号</th>
          <th width="60">商品类目</th>
          <th width="84">品名</th>
          <th width="48">材质</th>
          <th width="108">规格</th>
          <th width="48">数量</th>
          <th width="48">单位</th>
          <th width="60">材料成本</th>
          <th width="60">加工成本</th>
          <th width="60" v-if="!parent">销售单价</th>
          <th v-if="!parent">销售金额</th>
          <th v-if="parent">每套零件数量</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in selfData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.category }}</td>
          <td>{{ item.craft }}</td>
          <td>{{ item.texture }}</td>
          <td>{{ item.sales_specification }}</td>
          <td>{{ item.counts }}</td>
          <td>{{ item.unit }}</td>
          <td>¥{{ item.cost_price_unit }}</td>
          <td>¥{{ item.process_price_unit }}</td>
          <td v-if="!parent">¥{{ item.sales_price }}</td>
          <td v-if="!parent">¥{{ item.sales_amount }}</td>
          <td v-if="parent">{{ item.counts_unit }}</td>
        </tr>
      </tbody>
    </table>
    <template v-for="(item, index) in selfData">
      <PrintTable
        v-if="item.tableBomData.length"
        :key="index"
        :tableTitle="`${tableTitle}-${item.craft}`"
        :parent="item"
        :data="item.tableBomData"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'PrintTable',
  props: {
    tableTitle: {
      type: String,
      required: true
    },
    parent: {
      type: Object,
      default: () => {
        return null
      }
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfData: []
    }
  },
  computed: {
    // 表格标题
    tableTitleArr() {
      let arr = this.tableTitle.split('-')
      let result = arr.map((item, index) => {
        return {
          name: item,
          isActive: index === arr.length - 1
        }
      })
      return result
    }
  },
  mounted() {
    this.selfData = this.$util.deepCopy(this.data)
  }
}
</script>

<style lang="less" scoped>
.table-box {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
}
.table-box caption {
  padding-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.table-box th,
.table-box td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}
.table-box th {
  font-weight: normal;
  background: #e0e5eb;
}
</style>
